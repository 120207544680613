import {Injectable} from "@angular/core";
import {LocalStoreService} from "../services/local-store.service";
import {Constantes} from "../constantes";
import {environment} from "../../../environments/environment";
export class Permission{
  modulesCodes:String[] = [];
  actionsCodes :String[] = [];
}
@Injectable({
  providedIn: 'root'
})
export class Guard {
  constructor(
    private localStoreService: LocalStoreService
  ) {}
  permissions:Permission =new Permission()

  pass(codeAction:string = ''){
   // console.log("ACTION CODE ", codeAction)
    this.permissions =  this.localStoreService.getItem(Constantes.MODULE_ACTIONS_PERMIT);
   // console.log(this.permissions)
    if(environment.allowAll)
      return true ;
    return this.permissions.actionsCodes.includes(codeAction) ;
  }
}
