<div class=" hidden-xs d-flex align-items-center " *ngIf="guard.pass(autority?.appro_agence)">
  <div class="d-flex flex-row justify-content-around mr-2">
    <span class="text-small mr-1 pt-1">Agence:</span>
    <span class="badge badge-pill badge-light p-2 text-13 mr-1">{{currentAgence?.nom | titlecase}}</span>
  </div>
  <div class="d-flex flex-row justify-content-around">
    <span class="text-small mr-1 pt-1">Solde:</span>
    <span class="badge badge-pill p-2 text-13 mr-1" [ngClass]="{'badge-danger':currentAgence?.seuilAlerte > currentAgence?.solde,'badge-light':currentAgence?.solde > currentAgence?.seuilAlerte }">{{currentAgence?.solde | number}} {{entite?.devise}}</span>
  </div>
  <button class="btn btn-rounded btn-success ml-2 py-1 font-weight-500" (click)="approvisAgence()"><i-feather name="trending-up"></i-feather> Approvisionner mon solde</button>
</div>

<div class="d-flex align-items-center" *ngIf="guard.pass(autority?.appro_entite)">
  <div class="d-flex flex-row justify-content-around">
    <span class="text-small mr-1 pt-1">Solde:</span>
    <span class="badge badge-pill badge-light p-2 text-13 mr-1" [ngClass]="{'badge-danger':soldeEntite >  entite.seuilAlerte,'badge-light':soldeEntite > entite.seuilAlerte }">{{soldeEntite | number}} {{entite?.devise}}</span>
  </div>
  <div class="d-flex flex-row justify-content-around">
    <span class="text-small mr-1 pt-1">Solde Cash:</span>
    <span class="badge badge-pill badge-light p-2 text-13 mr-1" >{{soldeCash | number}} {{entite?.devise}}</span>
  </div>
  <button class="btn btn-rounded btn-success ml-2 py-1 font-weight-500" (click)="approvisEntite()"><i-feather name="trending-up"></i-feather> Approvisionner mon solde</button>
</div>
