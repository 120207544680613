import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnLoadingComponent } from './btn-loading/btn-loading.component';
import { FeatherIconComponent } from './feather-icon/feather-icon.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { SearchModule } from './search/search.module';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LayoutsModule } from './layouts/layouts.module';
import { AutocompleteUsersComponent } from './autocomplete-users/autocomplete-users.component';
import { SimplePaginationComponent } from './simple-pagination/simple-pagination.component';
import { RecaptchaComponent } from './recaptcha/recaptcha.component';
import {AutocompleteAgenceComponent} from './autocomplete-agence/autocomplete-agence.component'

const components = [
  BtnLoadingComponent,
  FeatherIconComponent,
  AutocompleteUsersComponent,
  SimplePaginationComponent,
  RecaptchaComponent,
  AutocompleteAgenceComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LayoutsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SearchModule,
    PerfectScrollbarModule,
    NgbModule
  ],
  declarations: components,
  exports: components
})
export class SharedComponentsModule { }
