<div class="main-header">
  <div class="logo hidden-xs" *ngIf="entite">
      <img [src]="entite.source">
  </div>

  <div class="menu-toggle" (click)="toggelSidebar()">
    <div></div>
    <div></div>
    <div></div>
  </div>
  <app-current-agence class="hideMobile" (approvSent)="approvSent($event)"></app-current-agence>
  <div class="m-auto"></div>
  <div class="header-part-right" >
    <div ngbDropdown [placement]="'bottom-right'" id="langDropdown" class="align-self-end lang-select line-height-4 d-none d-md-block d-lg-none">
      <button class="btn btn-default btn-lang-select" ngbDropdownToggle>{{ currentLang | titlecase }}</button>
      <div ngbDropdownMenu aria-labelledby="langDropdown">
        <a class="dropdown-item" (click)="changeLang(lang.isoCode)" *ngFor="let lang of languages">{{ lang.name | titlecase }}</a>
      </div>
    </div>
    <!-- <div ngbDropdown  [placement]="'bottom-center'" class="mega-menu d-none d-md-block">
      <button ngbDropdownToggle href="#" class="btn p-0 servicesButton dropdown-toggle"><i class="ion-md-apps"></i></button>
      <div ngbDropdownMenu perfectScrollbar>
          <div class="row m-0">
            <div class="col-md-6 p-2">
              <div class="card-service o-hidden p-2">
                <img class="card-img" src="./assets/images/transfer.png" alt="Card image">
                <p class="mb-2 text-16 font-weight-light">Transfert d'argent</p>
              </div>
            </div>
            <div class="col-md-6 p-2">
              <div class="card-service o-hidden p-2">
                <img class="card-img" src="./assets/images/wallet.png" alt="Card image">
                <p class="mb-2 text-16 font-weight-light">Wallet</p>
              </div>
            </div>
            <div class="col-md-6 p-2">
              <div class="card-service o-hidden p-2">
                <img class="card-img" src="./assets/images/mass_payment.png" alt="Card image">
                <p class="mb-2 text-16 font-weight-light">Paiement de masse</p>
              </div>
            </div>
            <div class="col-md-6 p-2">
              <div class="card-service o-hidden p-2">
                <img class="card-img" src="./assets/images/payment.png" alt="Card image">
                <p class="mb-2 text-16 font-weight-light">Paiement divers</p>
              </div>
            </div>
          </div>
      </div>
    </div> -->
    <div class="col-sm-12 user col align-self-end mr-auto m-auto" *ngIf="currentUser" [placement]="'bottom-right'" ngbDropdown>
      <div id="userDropdown"  ngbDropdownToggle class="d-flex flex-row text-left user-profile-info" >
        <ngx-avatar [src]="currentUser.photo" size="40" name="{{currentUser.fullName}}"></ngx-avatar>
        <div class="d-flex flex-column pr-4">
       <div  class=" mb-0 mt-0 ml-2 user-fullname text-small">{{currentUser.username}}</div>
          <div class="mb-0 mt-0 ml-2 text-gray-600 text-small"><span class="badge badge-pill badge-light">{{currentUser.roles | titlecase}}</span></div>
        </div>
      </div>
      <div ngbDropdownMenu aria-labelledby="userDropdown" class="pb-0">
        <div class="dropdown-header font-weight-bold text-dark">
          <i class="i-Lock-User mr-1"></i> {{currentUser.fullName | titlecase}}
        </div>

        <a class="dropdown-item" [routerLink]="'/users/account/profile'">Mon Profil</a>
        <button class="dropdown-item" (click)="signout()">Déconnexion</button>
      </div>
    </div>
  </div>
</div>
<ngb-alert class="alert-card text-center" [type]="'success'" (close)="alertMessage = ''" *ngIf="alertMessage" [ngStyle]="{'z-index':300,position:'absolute',top:'10px',width:'100%'}">
  {{alertMessage}}
</ngb-alert>
