import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import {AskiaComponent} from './views/askia/askia.component';

const adminRoutes: Routes = [
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'teams',
      loadChildren: () => import('./views/teams/teams.module').then(m => m.TeamsModule)
    },
    {
      path: 'users',
      loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule)
    },
    {
      path: 'points-service',
      loadChildren: () => import('./views/agences/agences.module').then(m => m.AgencesModule)
    },
  {
      path: 'cooperative',
      loadChildren: () => import('./views/cooperative/cooperative.module').then(m => m.CooperativeModule)
    },
    {
      path: 'transactions',
      loadChildren: () => import('./views/transactions/transactions.module').then(m => m.TransactionsModule)
    },
    {
      path: 'reporting',
      loadChildren: () => import('./views/reporting/reporting.module').then(m => m.ReportingModule)
    },
    {
      path: 'caisses',
      loadChildren: () => import('./views/caisses/caisses.module').then(m => m.CaissesModule)
    },
    {
      path: 'wallet',
      loadChildren: () => import('./views/wallet/wallet.module').then(m => m.WalletModule)
    },
    {
      path: 'Transfert',
      loadChildren: () => import('./views/transferts/transferts.module').then(m => m.TransfertsModule)
    },
    {
      path: 'settings',
      loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule)
    },
    {
      path: 'history',
      loadChildren: () => import('./views/logs/logs.module').then(m => m.LogsModule)
    },
    {
      path: 'enrollments',
      loadChildren: () => import('./views/enrollements/enrollements.module').then(m => m.EnrollementsModule)
    },
    {
      path: 'messages',
      loadChildren: () => import('./views/message/message.module').then(m => m.MessageModule)
    },
    {
      path: 'cartes',
      loadChildren: () => import('./views/cartes/cartes.module').then(m => m.CartesModule)
    },
   {
      path: 'brouillard',
      loadChildren: () => import('./views/brouillard/brouillard.module').then(m => m.BrouillardModule)
    },
  {
      path: 'limitations',
      loadChildren: () => import('./views/limitations/limitations.module').then(m => m.LimitationsModule)
    },{
      path: 'regles',
      loadChildren: () => import('./views/regles/regles.module').then(m => m.ReglesModule)
    },{
      path: 'campagne',
      loadChildren: () => import('./views/campagne/campagne.module').then(m => m.CampagneModule)
    },{
      path: 'versements',
      loadChildren: () => import('./views/versements/versements.module').then(m => m.VersementsModule)
    },
    {
      path: 'pisteur',
      loadChildren: () => import('./views/pisteur/pisteur.module').then(m => m.PisteurModule)
    },{
      path: 'articles',
      loadChildren: () => import('./views/articles/articles.module').then(m => m.ArticlesModule)
    },
  {
    path:'askia',
    component:AskiaComponent
  }

  ];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
