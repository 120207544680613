import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApprovService {

  constructor( private http: HttpClient ) { }

  getApprovis(query, page = 1) {
    return this.http.get<any[]>(`${environment.apiReseauUrl}/approvisionnement_agences?_page=${page}${query}`);
  }
  getApprovisPisteur(query, page = 1) {
    return this.http.get<any[]>(`${environment.apiReseauUrl}/approvisionnement_caisses?_page=${page}${query}`);
  }

  getAllApprovis(query, page = 1) {
    return this.http.get<any[]>(`${environment.apiReseauUrl}/approvisionnement_agences?_page=${page}${query}`);
  }

  getApprovCaisses(page = 1,query) {
    return this.http.get<any[]>(`${environment.apiReseauUrl}/approvisionnement_caisses?_page=${page}${query}`);
  }

  acceptApprovis(id,montant) {
    return this.http.put<any[]>(`${environment.apiReseauUrl}/approvisionnement_agences/validation/${id}`, {'montant':montant});
  }

  rejectApprovis(id,motif) {
    return this.http.put<any[]>(`${environment.apiReseauUrl}/approvisionnement_agences/rejeter/${id}`, {'motif':motif});
  }

  getAgenceApprovis(agenceId) {
    return this.http.get<any[]>(`${environment.apiReseauUrl}/approvisionnement_agences?agence.id=${agenceId}`);
  }

  getPointDistributeurApprovis() {
    return this.http.get<any[]>(`${environment.apiReseauUrl}/approvisionnement_agences`);
  }

  getCaissesDistributeurApprovis() {
    return this.http.get<any[]>(`${environment.apiReseauUrl}/approvisionnement_caisses`);
  }

  rejectApprovisCaisse(id,motif) {
    return this.http.put<any[]>(`${environment.apiReseauUrl}/approvisionnement_caisses/rejeter/${id}`, {'motif':motif});
  }

  acceptApprovisCaisse(id,montant) {
    return this.http.put<any[]>(`${environment.apiReseauUrl}/approvisionnement_caisses/validation/${id}`, {'montant':montant});
  }

  approvisionnerAgence(params){
    return this.http.post<any[]>(`${environment.apiReseauUrl}/approvisionnement_agences`,params);
  }

  approvisionnerEntite(params){
    return this.http.post<any[]>(`${environment.apiAdminUrl}/approvisionnements`,params);
  }
  demandeApproEntite(params){
    return this.http.post<any[]>(`${environment.apiWalletUrl}/demande_appro_carte_entites`,params);
  }

  exportCaisses(type,query){
    return this.http.get(`${environment.apiReseauUrl}/approvisionnement_caisses/${type}?true=true${query}`,{observe:'response', responseType:'blob'});
  }

  exportAgences(type,query:string){
    return this.http.get(`${environment.apiReseauUrl}/approvisionnement_agences/${type}?true=true${query}`,{observe:'response', responseType:'blob'});
  }

  exportDuplicata(id) {
    return this.http.get(`${environment.apiReseauUrl}/approvisionnement/agence/recu/pdf/${id}`,{observe:'response', responseType:'blob'});
  }

  exportDuplicataCaisseApprov(id) {
    return this.http.get(`${environment.apiReseauUrl}/approvisionnement/caisse/export/pdf/${id}`,{observe:'response', responseType:'blob'});
  }


}
