import {Compiler, Component, OnInit} from '@angular/core';
import { NavigationService, IMenuItem } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { User } from '../../../../models/user';
import { Entity } from 'src/app/shared/models/entity.model';
import { TranslateService } from '@ngx-translate/core';
import { Constantes } from 'src/app/shared/constantes';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { PermissionsService } from 'src/app/shared/services/permissions.service';

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {

    notifications: any[];
    entite: any;
    currentUser: User;
    entity: Entity;
    serviceExternes: any[];
    nav: IMenuItem[];
    currentLang: string;
    loading: boolean;
    languages = [{isoCode:'fr',name:'Français'}, {isoCode:'ar',name:'Arabe'}];
    alertMessage: string;

    constructor(
      private _translateService: TranslateService,
      private navService: NavigationService,
      public searchService: SearchService,
      private _authService: AuthService,
      private _localStoreService:LocalStoreService,
      private  translate:TranslateService,
      private _permissionsService: PermissionsService,
      private _compiler: Compiler
    ) {
      this.notifications = [
      ];
    }

    ngOnInit() {
      this.initPermissions();
      this.entite = this._localStoreService.getItem(Constantes.CURRENT_ENTITY);
      this.setLang(this.entite.lang);
      this.setPrimaryColor(this.entite.couleur);
      this.currentUser = new User(this._authService.currentUser());
      this.currentLang = this._translateService.currentLang;

      //Liste des elements du menu
      this.navService.menuItems$
			.subscribe((items) => {
        this.nav = items;
      });
    }


    initPermissions() {
      this._permissionsService.setPermissions();
    }

    toggelSidebar() {
      const state = this.navService.sidebarState;
      if (state.childnavOpen && state.sidenavOpen) {
        return state.childnavOpen = false;
      }
      if (!state.childnavOpen && state.sidenavOpen) {
        return state.sidenavOpen = false;
      }
      if (!state.sidenavOpen && !state.childnavOpen) {
          state.sidenavOpen = true;
          setTimeout(() => {
              state.childnavOpen = true;
          }, 50);
      }
    }

    changeLang(lang) {
      this.currentLang = lang;
      this._translateService.use(lang);
    }


    signout() {
      this._authService.signout();
    }

    approvSent(message) {
      this.alertMessage = message;
    }

    setLang(lang){
      this.translate.setDefaultLang('fr');
      this.translate.use(lang || 'fr');
    }

    setPrimaryColor(colour) {
      const body = document.getElementsByTagName('body')[0];
      body.style.setProperty('--entity-primary', colour);
    }
}
