import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PermissionsService} from './permissions.service';
import {UserPermissions} from '../user_permissions';
import {LocalStoreService} from './local-store.service';
import {Constantes} from '../constantes';
const CODE_ENTITE ={
  AGRIDEV:'agridev'
}
export interface IMenuItem {
  id?: string;
  title?: string;
  description?: string;
  type: string;       // Possible values: link/dropDown/extLink
  name?: string;      // Used as display text for item and title for separator type
  name_entite?: NameEntity[];      // Used as display text for item and title for separator type
  icone_entite?: NameEntity[];      // Used as display text for item and title for separator type
  state?: string;     // Router state
  icon?: string;      // Material icon name
  tooltip?: string;   // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  active?: boolean;
}
export interface NameEntity{
  code:string;
  value:string;
}
export interface IChildItem {
  id?: string;
  parentId?: string;
  type?: string;
  name: string;       // Display text
  name_entite?: NameEntity[];       // Display text
  state?: string;     // Router state
  icon?: string;
  sub?: IChildItem[];
  active?: boolean;
}

interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}

interface ISidebarState {
  sidenavOpen?: boolean;
  childnavOpen?: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class NavigationService {
  public menu = [];
  public codeModules = [];
  public sidebarState: ISidebarState = {
    sidenavOpen: true,
    childnavOpen: false
  };
  codeClient;

  constructor(
    private _permissionService: PermissionsService, private store: LocalStoreService,
    private _localStoreService: LocalStoreService
  ) {

    this._permissionService.permissionsCodes.subscribe((permissions) => {
      this.codeClient = this.store.getItem(Constantes.CURRENT_CODE_ENTITY);
      this.publishNavigationChange(permissions);
    });

  }

  defaultMenu: IMenuItem[] = this.menu;

  manageAgenceMenuItem: IMenuItem = {
    name: 'Points de ventes',
    name_entite:[
      {
        code:CODE_ENTITE.AGRIDEV,
        value:'Agences',
      }
    ],
    description: 'Votre Tableau de bord',
    icon: 'i-Network-Window',
    type: 'dropDown',
    sub: []
  };
  manageCooperativeMenuItem: IMenuItem = {
    name: 'Coopératives',
    description: 'Votre Tableau de bord',
    icon: 'i-Network-Window',
    type: 'dropDown',
    sub: []
  };

  manageUsersMenuItem: IMenuItem = {
    icon: 'i-MaleFemale',
    name: 'Personnel',
    name_entite:[
      {
        code:CODE_ENTITE.AGRIDEV,
        value:'Utilisateur',
      }
    ],
    type: 'dropDown',
    sub: []
  };

  dashBoardMenutItem: IMenuItem = {
      name: 'Tableau de bord',
      description: 'Votre Tableau de bord',
      icon: 'i-Dashboard',
      state: '/dashboard',
      type: 'link'
    };

  manageReportingMenuItem: IMenuItem = {
      icon: 'i-Line-Chart',
      name: 'Reporting',
      type: 'dropDown',
      sub: []
    };
    manageWalletMenuItem: IMenuItem = {
      name: 'Clients',
    name_entite:[
      {
        code:CODE_ENTITE.AGRIDEV,
        value:'Producteur',
      }
    ],
      icon: 'i-MaleFemale',
      type: 'dropDown',
      sub: []
    };
  manageLogsMenuItem: IMenuItem = {
      name: 'Logs',
      icon: 'i-Clock-Back',
      type: 'link',
      state: '/history'
    };

  manageMessagesMenuItem: IMenuItem = {
      name: 'Message',
      icon: 'i-Email',
      type: 'link',
      state: '/messages/list'
    };
  manageVersementMenuItem: IMenuItem = {
      name: 'Versement',
      icon: 'i-Money-Bag',
    type: 'dropDown',
    sub: []
    };
  manageTransfersMenuItem: IMenuItem = {
      name: 'Transferts',
      icon: 'i-Arrow-Turn-Right',
      type: 'dropDown',
      sub: []
    };
  manageTransactionsMenuItem: IMenuItem = {
      name: 'Transactions',
      icon: 'i-Sync',
      type: 'dropDown',
      sub: []
    };
  manageCardsMenuItem: IMenuItem = {
      name: 'Cartes',
      icon: 'i-Folders',
      type: 'dropDown',
      sub: []
    };
  manageCampagneMenuItem: IMenuItem = {
      name: 'Campagnes',
      icon: 'i-Folders',
      type: 'dropDown',
      sub: []
    };
  manageCaissesMenuItem: IMenuItem = {
    name: 'Caisses',
    name_entite:[
      {
        code:CODE_ENTITE.AGRIDEV,
        value:'Points Marchand',
      }
    ],
    icon: 'i-Cash-register-2',
    type: 'dropDown',
    sub: []
  };
  manageLimitationMenuItem: IMenuItem = {
    name: 'Limitations',
    type: 'dropDown',
    icon: 'i-Gears',
    sub: []
  };
  manageReglesMenuItem: IMenuItem = {
    name: 'Regles',
    type: 'dropDown',
    icon: 'i-Gears',
    sub: []
  };
  manageEnrolementMenuItem: IMenuItem = {
    name: 'Enrôlement',
    type: 'dropDown',
    icon: 'i-Network-Window',
    sub: []
  };
  managePISTEURMenuItem: IMenuItem = {
    name: 'PISTEUR',
    type: 'dropDown',
    icon: 'i-MaleFemale',
    sub: []
  };
  manageArticleMenuItem: IMenuItem = {
    name: 'Articles',
    type: 'dropDown',
    icon: 'i-Share',
    sub: []
  };
  adminMenu: IMenuItem[] = [];
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  publishNavigationChange(permissions) {
    const {modulesCodes, actionsCodes} = permissions;
    this._localStoreService.setItem(Constantes.MODULE_ACTIONS_PERMIT, permissions);
    if (!(modulesCodes && modulesCodes.length > 0)) {
      return;
    }
    if (!(actionsCodes && actionsCodes.length > 0)) {
      return;
    }
    this.adminMenu = [];
    this.dashboard(modulesCodes, actionsCodes);
    this.manageUsers(modulesCodes, actionsCodes);
    this.manageAgencies(modulesCodes, actionsCodes);
    this.manageCooperative(modulesCodes, actionsCodes);
    this.manageCaisses(modulesCodes, actionsCodes);
    this.manageReporting(modulesCodes, actionsCodes);
    this.manageTransactions(modulesCodes, actionsCodes);
    this.manageTransfers(modulesCodes, actionsCodes);
    this.manageMessages(modulesCodes, actionsCodes);
    this.manageWallet(modulesCodes, actionsCodes);
    this.manageCards(modulesCodes, actionsCodes);
    this.manageLogs(modulesCodes, actionsCodes);
    this.manageLimite(modulesCodes, actionsCodes);
    this.manageRegles(modulesCodes, actionsCodes);
    this.manageEnolementCaisse(modulesCodes, actionsCodes);
    this.manageCampagne(modulesCodes, actionsCodes);
    this.manageVersements(modulesCodes, actionsCodes);
    this.managePisteur(modulesCodes, actionsCodes);
    this.manageArticles(modulesCodes, actionsCodes);
    this.menuItems.next(this.adminMenu);
    // console.log("menuItems: ",this.menuItems);
  }
  dashboard(modulesCodes, actionsCodes) {
    if (modulesCodes.includes(UserPermissions.MANAGE_DASHBOARD.module)) {
      this.adminMenu.push(this.dashBoardMenutItem);
    }
  }
  // manage dashboard
  // manageDashboard(modulesCodes, actionsCodes) {
  //
  //   if (modulesCodes.includes(UserPermissions.MANAGE_DASHBOARD.module) && actionsCodes.includes(UserPermissions.MANAGE_DASHBOARD.actions.viewDashboard)) {
  //     this.adminMenu.push(this.dashBoardMenutItem);
  //   }
  // }

  manageTransactions(modulesCodes, actionsCodes) {
    this.manageTransactionsMenuItem.sub = [];
    if (modulesCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.module)) {
      this.adminMenu.push(this.manageTransactionsMenuItem);
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactions)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: 'Listes', state: '/transactions', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactionsCltW)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: 'Transactions client wallet',name_entite:[{code:CODE_ENTITE.AGRIDEV,value:'Transactions producteurs'}], state: '/transactions/trsWallet', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactionsPending)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: 'Transactions en attente', state: '/transactions/pending', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.transfertUV) && this.codeClient != 'digicash') {
      this.manageTransactionsMenuItem.sub.push(
        {name: 'Transfert UV', state: '/transactions/reversement', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.appeldefond)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: 'Appel de fond', state: '/transactions/appel-de-fonds', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactionSimbot)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: 'Transactions Simbot', state: '/transactions/simbot', type: 'link'}
      );
    }if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactionPartener)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: 'Transactions Partenaire', state: '/transactions/partenaire', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactionGain)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: 'Transactions Gain Loto', state: '/transactions/gains', type: 'link'}
      );
    } if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactionInt)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: 'Transactions International en attente', state: '/transactions/transfertint', type: 'link'}
      );
    }if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewtransactionsCash)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: 'Transactions Cash', state: '/transactions/cash', type: 'link'}
      );
    }
  }

  manageCaisses(modulesCodes, actionsCodes) {
    this.manageCaissesMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_CAISSES.module)) {
      this.adminMenu.push(this.manageCaissesMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_CAISSES.actions.view)) {
      this.manageCaissesMenuItem.sub.push(
        {name: 'Liste', state: '/caisses', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_CAISSES.actions.viewMarchands)) {
      this.manageCaissesMenuItem.sub.push(
        {name: 'Liste Marchands', state: '/caisses/marchands', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_CAISSES.actions.viewApprovs)) {
      this.manageCaissesMenuItem.sub.push(
        {name: 'Approvisionnements caisse', state: '/caisses/approvisionnements', type: 'link'}
      );
    }
  }

  manageLimite(modulesCodes, actionsCodes) {
    this.manageLimitationMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_LIMITATION.module)) {
      this.adminMenu.push(this.manageLimitationMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_LIMITATION.actions.viewlimitGeneral)) {
      this.manageLimitationMenuItem.sub.push(
        {name: 'General', state: '/limitations/general', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_LIMITATION.actions.viewlimitCarte)) {
      this.manageLimitationMenuItem.sub.push(
        {name: 'Carte', state: '/limitations/cartes', type: 'link'});
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_LIMITATION.actions.viewlimitTpe)) {
      this.manageLimitationMenuItem.sub.push(
        {name: 'TPE', state: '/limitations/tpe', type: 'link'});
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_LIMITATION.actions.viewlimitDab)) {
      this.manageLimitationMenuItem.sub.push(
        {name: 'DAB', state: '/limitations/gab', type: 'link'});
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_LIMITATION.actions.viewlimitWallet)) {
      this.manageLimitationMenuItem.sub.push(
        {name: 'Wallet', state: '/limitations/wallet', type: 'link'});
    }
  }

  manageRegles(modulesCodes, actionsCodes) {
    this.manageReglesMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_REGLES.module)) {
      this.adminMenu.push(this.manageReglesMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_REGLES.actions.viewRegles)) {
      this.manageReglesMenuItem.sub.push(
        {name: 'Liste', state: '/regles/liste', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_REGLES.actions.createRegle)) {
      this.manageReglesMenuItem.sub.push(
        {name: 'Evenements', state: '/regles/evenements', type: 'link'});
    }
  }

  manageEnolementCaisse(modulesCodes, actionsCodes) {
    this.manageEnrolementMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_ENROLEMENT.module)) {
      this.adminMenu.push(this.manageEnrolementMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_ENROLEMENT.actions.create)) {
      this.manageEnrolementMenuItem.sub.push(
        {name: 'Nouveau', state: '/enrollments/new', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_ENROLEMENT.actions.list)) {
      this.manageEnrolementMenuItem.sub.push(
        {name: 'Liste', state: '/enrollments/list', type: 'link'});
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_ENROLEMENT.actions.createPiece)) {
      this.manageEnrolementMenuItem.sub.push(
        {name: 'Nouvelle piéce conformité', state: '/enrollments/piece', type: 'link'});
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_ENROLEMENT.actions.listPiece)) {
      this.manageEnrolementMenuItem.sub.push(
        {name: 'Liste piéce conformité', state: '/enrollments/pieceliste', type: 'link'});
    }
  }
  manageUsers(modulesCodes, actionsCodes) {
    this.manageUsersMenuItem.sub = [];
    if (modulesCodes.includes(UserPermissions.MANAGE_USERS.module)) {
      this.adminMenu.push(this.manageUsersMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_USERS.actions.viewUsers)) {
      this.manageUsersMenuItem.sub.push(
        {name: 'Utilisateurs', state: '/users/list', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_USERS.actions.viewPermissions)) {
      this.manageUsersMenuItem.sub.push(
        {name: 'Permissions', state: '/users/permissions', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_USERS.actions.viewRoles)) {
      this.manageUsersMenuItem.sub.push(
        {name: 'Rôles', state: '/users/roles', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_USERS.actions.lacation)) {
      this.manageUsersMenuItem.sub.push(
        {name: 'Localisation des points', state: '/users/location', type: 'link'}
      );
    }
  }

  manageAgencies(modulesCodes, actionsCodes) {
    this.manageAgenceMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_AGENCIES.module)) {
      this.adminMenu.push(this.manageAgenceMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_AGENCIES.actions.viewAgencies)) {
      this.manageAgenceMenuItem.sub.push(
        {name: 'Agences',name_entite:[{code:CODE_ENTITE.AGRIDEV,value:'Agence'}], state: '/points-service/agences', type: 'link'}
      );
    }if (actionsCodes.includes(UserPermissions.MANAGE_AGENCIES.actions.viewDist)) {
      this.manageAgenceMenuItem.sub.push(
        {name: 'Distributeurs', state: '/points-service/distributeurs', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_AGENCIES.actions.viewAppros)) {
      this.manageAgenceMenuItem.sub.push(
        {name: 'Approvisionnements', state: '/points-service/approvisionnements', type: 'link'}
      );
    }
  }
  manageCooperative(modulesCodes, actionsCodes) {
    this.manageCooperativeMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_COOPERATIVE.module)) {
      this.adminMenu.push(this.manageCooperativeMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_COOPERATIVE.actions.viewcooparative)) {
      this.manageCooperativeMenuItem.sub.push(
        {name: 'liste Coopèrative', state: '/cooperative/list', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_COOPERATIVE.actions.approcooparative)) {
      this.manageCooperativeMenuItem.sub.push(
        {name: 'Approvisionnements', state: '/cooperative/appro_cooperative', type: 'link'}
      );
    }
  }

  manageReporting(modulesCodes, actionsCodes) {
    this.manageReportingMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_REPORTING.module)) {
      this.adminMenu.push(this.manageReportingMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.viewAll)) {
      this.manageReportingMenuItem.sub.push(
        {name: 'Tous', state: '/reporting/all', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.viewCredits)) {
      this.manageReportingMenuItem.sub.push(
        {name: 'Encaissements', state: '/reporting/encaissement', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.viewDebits)) {
      this.manageReportingMenuItem.sub.push(
        {name: 'Décaissements', state: '/reporting/decaissement', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.followAccounts)) {
      this.manageReportingMenuItem.sub.push(
        {name: 'Suivi comptes', state: '/reporting/compte', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.soldesTelephone)) {
      this.manageReportingMenuItem.sub.push(
        {name: 'Solde compte téléphone', state: '/reporting/soldetel', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.messageTelephone)) {
      this.manageReportingMenuItem.sub.push(
        {name: 'Message téléphone', state: '/reporting/messagetel', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.situationCaisse)) {
      this.manageReportingMenuItem.sub.push(
        {name: 'Situation caisse', state: '/reporting/situation_caisse', type: 'link'}
      );
    }
    // if(actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.reportingCarte)) {
    //   this.manageReportingMenuItem.sub.push(
    //     {name: 'Reporting cartes', state: '/cartes/reporting', type: 'link'}
    //   )
    // }
  }

  manageWallet(modulesCodes, actionsCodes) {
    this.manageWalletMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_WALLET.module)) {
      this.adminMenu.push(this.manageWalletMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_WALLET.actions.viewClients)) {
      this.manageWalletMenuItem.sub.push(
        {name: 'Clients',name_entite:[{code:CODE_ENTITE.AGRIDEV,value:'Producteurs'}], state: '/wallet/clients', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_WALLET.actions.viewLimitations)) {
      this.manageWalletMenuItem.sub.push(
        {name: 'Liste En Attente', state: '/wallet/fraude', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_WALLET.actions.deplafondClient)) {
      this.manageWalletMenuItem.sub.push(
        {name: 'Déplafonnements', state: '/wallet/deplafond', type: 'link'}
      );
    }


  }

  manageLogs(modulesCodes, actionsCodes) {

    if (modulesCodes.includes(UserPermissions.MANAGE_LOGS.module) && actionsCodes.includes(UserPermissions.MANAGE_LOGS.actions.viewLogs)) {
      this.adminMenu.push(this.manageLogsMenuItem);
    }
  }

  manageMessages(modulesCodes, actionsCodes) {
    if (modulesCodes.includes(UserPermissions.MANAGE_LOGS.module) && actionsCodes.includes(UserPermissions.MANAGE_LOGS.actions.viewMessages)) {
      this.adminMenu.push(this.manageMessagesMenuItem);
    }
  }
  manageVersements(modulesCodes, actionsCodes) {
    this.manageVersementMenuItem.sub = [];
    if (modulesCodes.includes(UserPermissions.MANAGE_VERSEMENTS.module)) {
      this.adminMenu.push(this.manageVersementMenuItem);
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_VERSEMENTS.actions.addVersementsB)) {
      this.manageVersementMenuItem.sub.push(
        {name: 'Nouveau  versement banque', state: '/versements/add', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_VERSEMENTS.actions.addAppeldefond)) {
      this.manageVersementMenuItem.sub.push(
        {name: 'Nouveau  appel de  fond', state: '/versements/addAgence/agence', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_VERSEMENTS.actions.addVersementAgence)) {
      this.manageVersementMenuItem.sub.push(
        {name: 'Nouveau  versement Agence', state: '/versements/addAgence/autre', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_VERSEMENTS.actions.viewVersements)) {
      this.manageVersementMenuItem.sub.push(
        {name: 'Liste des  Versements', state: '/versements', type: 'link'}
      );
    }

  }

  manageTransfers(modulesCodes, actionsCodes) {
    this.manageTransfersMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_TRANSFERS.module)) {
      this.adminMenu.push(this.manageTransfersMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSFERS.actions.viewCancels)) {
      this.manageTransfersMenuItem.sub.push(
        {name: 'Annulation', state: '/Transfert/annulation', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSFERS.actions.viewRefunds)) {
      this.manageTransfersMenuItem.sub.push(
        {name: 'Remboursement', state: '/Transfert/remboursement', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSFERS.actions.viewFundsTransfers)) {
      this.manageTransfersMenuItem.sub.push(
        {name: 'Transfert de fonds', state: '/Transfert/a-to-a-transfers', type: 'link'}
      );
    }
  }

  manageCards(modulesCodes, actionsCodes) {
    this.manageCardsMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_CARDS.module)) {
      this.adminMenu.push(this.manageCardsMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.agencesDistrib)) {
      this.manageCardsMenuItem.sub.push(
        {name: 'Distributions agences', state: '/cartes/distribution', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.caissesDistrib)) {
      this.manageCardsMenuItem.sub.push(
        {name: 'Distributions caisse', state: '/cartes/distributionCaisse', type: 'link'}
      );
    }


    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.received)) {
      this.manageCardsMenuItem.sub.push(
        {name: 'Réceptions', state: '/cartes/reception', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.cardsPrices)) {
      this.manageCardsMenuItem.sub.push(
        {name: 'Prix Cartes', state: '/cartes/price', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.availableCards)) {
      this.manageCardsMenuItem.sub.push(
        {name: 'Liste des cartes ', state: '/cartes/stock', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.cartesbloquees)) {
      this.manageCardsMenuItem.sub.push(
        {name: 'Cartes Bloquèes ', state: '/cartes/bloque', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.retourCaisse)) {
      this.manageCardsMenuItem.sub.push(
        {name: 'Retour Cartes-Caisse', state: '/cartes/retour/caisse', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.retourAgence)) {
      this.manageCardsMenuItem.sub.push(
        {name: 'Retour Cartes-Agence', state: '/cartes/retour/agence', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.receivedAgence)) {
      this.manageCardsMenuItem.sub.push(
        {name: 'Réception Cartes-Agence', state: '/cartes/reception/agence', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.approvs)) {
      this.manageCardsMenuItem.sub.push(
        {name: 'Approvisionnements', state: '/cartes/appro', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.search)) {
      this.manageCardsMenuItem.sub.push(
        {name: 'Recherche Carte', state: '/cartes/recherche', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.reporting)) {
      this.manageCardsMenuItem.sub.push(
        {name: 'Reporting', state: '/cartes/reporting', type: 'link'}
      );
    }
  }
  manageCampagne(modulesCodes, actionsCodes) {
    this.manageCampagneMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_CAMPAGNE.module)) {
      this.adminMenu.push(this.manageCampagneMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_CAMPAGNE.actions.viewCampagne)) {
      this.manageCampagneMenuItem.sub.push(
        {name: 'Liste campagne', state: '/campagne/list', type: 'link'}
      );
    }
  }
  manageArticles (modulesCodes, actionsCodes) {
    this.manageArticleMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_ARTICLESS.module)) {
      this.adminMenu.push(this.manageArticleMenuItem);
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_ARTICLESS.actions.viewArticles)) {
      this.manageArticleMenuItem.sub.push(
        {name: 'Liste des articles', state: '/articles', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_ARTICLESS.actions.viewDistributionAg)) {
      this.manageArticleMenuItem.sub.push(
        {name: 'Liste distribution', state: '/articles/list', type: 'link'}
      );
    }if (actionsCodes.includes(UserPermissions.MANAGE_ARTICLESS.actions.viewDistributionAg)) {
      this.manageArticleMenuItem.sub.push(
        {name: 'Liste des livrets', state: '/articles/list_livret', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_ARTICLESS.actions.newDistributionAg)) {
      this.manageArticleMenuItem.sub.push(
        {name: 'Nouveau distribution agence', state: '/articles/dist_agence', type: 'link'}
      );
    }if (actionsCodes.includes(UserPermissions.MANAGE_ARTICLESS.actions.newDistributionAc)) {
      this.manageArticleMenuItem.sub.push(
        {name: 'Nouveau distribution caisse', state: '/articles/dist_caisse', type: 'link'}
      );
    }if (actionsCodes.includes(UserPermissions.MANAGE_ARTICLESS.actions.viewStock)) {
      this.manageArticleMenuItem.sub.push(
        {name: 'Répartion stock', state: '/articles/stock', type: 'link'}
      );
    }
  }
  managePisteur(modulesCodes, actionsCodes) {
    this.managePISTEURMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_PISTEUR.module)) {
      this.adminMenu.push(this.managePISTEURMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_PISTEUR.actions.viewpisteur)) {
      this.managePISTEURMenuItem.sub.push(
        {name: 'Liste des Pisteur', state: '/pisteur/list', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_PISTEUR.actions.viewappropisteur)) {
      this.managePISTEURMenuItem.sub.push(
        {name: 'Approvissionnement', state: '/pisteur/appro', type: 'link'}
      );
    }if (actionsCodes.includes(UserPermissions.MANAGE_PISTEUR.actions.viewreverspisteur)) {
      this.managePISTEURMenuItem.sub.push(
        {name: 'Appel de fond', state: '/pisteur/appeldefond', type: 'link'}
      );
    }
  }
}
