const BASE_API = 'https://proxy.pdg.sn';
const DIGI_MP_API = 'https://guichet-prod-mass-payment-services.bgdigital.cloud/api';
export const environment = {
  allowAll:false,
  production: false,
  apiAuthUrl: BASE_API+'/auth/api',
  apiUrl: BASE_API+'/auth/api',
  apiAdminUrl: BASE_API+'/admin/api',
  apiReseauUrl: BASE_API+'/reseau/api',
  apiTransfertUrl: BASE_API+'/transfert/api',
  apiWalletUrl: BASE_API+'/wallet/api',
  apiComplianceUrl: 'https://fraude-services.bgdigital.tech/',
  apiMassPaymentUrl: BASE_API+'/dgpsn/api' ,
  apiSimBotUrl: BASE_API+'/simbot/api',
  apiInsuranceUrl: BASE_API+'/insurance/api',
  apiLogsUrl: BASE_API+'/logs/api',
  apiOsticketUrl: BASE_API+'/osticket/api',
  urlFront: 'https://admin.nt-albey.ne/#/sessions/signin',
  apiParterUrl: '/partner/api',
  apiAmlUrl: BASE_API + '/aml/api',

};
